const themeGen = theme => ({  // eslint-disable-line
  productNameWidth: 'ic-col-5',
  totalColumnWidth: 'ic-col-1',
  conf: {
    showCartPriceTitle: false,
    showProductUnit: true,
  },
  countButtonColor: 'tertiary',
});

export default themeGen;
