import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getThemeFactory } from '@ic-theme';
import Button, { ButtonColors } from '@ui-elem/Button/Button';
import { ForDesktop, ForNonDesktop } from 'ic/ui-elem/Responsive';
import QuantityCounterMod from 'eco/QuantityCounter/QuantityCounter.jsx';
import localeSvc, { str } from 'seco/localeSvc';
import getColorFromCode from 'ic/sites/minespresso/getColorFromCode';
import StockStatus from '@spa-ec/components/StockStatus/StockStatus';
import CleanText from 'ic/ui-elem/CleanText.jsx';
import { PodImg, ProductImg } from 'deco/ProductImage/ProductImage';
import Characteristics from './Characteristics.jsx';
import { Icons } from '@ui-elem/Icon/Icon';
import { NAME as sessionReducerName } from '@spa-core/store/app/constants';
import { getReducer } from '@spa-core/legacy-adapter/utils';

const name = 'ProductListing';

const th = getThemeFactory(name, {
  addToCartPanelWrapPoint: 777,
});

const theme = th();

export const navigation = {
  navigateTo: (history, url) => {
    history.push(url);
  },
};

const StyledPurchaseBox = styled.div`
  &&& {
    .purchase-box {
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        display: inline;
      }
    }
  }
`;

const Info = styled.div`
  &&& {
    display: flex;
    justify-content: center;
    margin-top: ${(p) => p.theme.space / 2}rem;
  }
`;

const PurchaseBoxInfoSection = (p) => {
  const sessionConfig = getReducer(sessionReducerName).sessionConfig;
  return (
    <Info>
      <StrikeThrough className={'text-sm font-bold text-center'}>
        {sessionConfig.isDecimalPricingEnabled ? localeSvc.formatDecPrice(p.price) : localeSvc.formatLongPrice(p.price)}
      </StrikeThrough>
      <Discount className={'text-sm font-bold text-center'}>
        {sessionConfig.isDecimalPricingEnabled
          ? localeSvc.formatDecPrice(p.discountedPrice)
          : localeSvc.formatLongPrice(p.discountedPrice)}
      </Discount>
    </Info>
  );
};

const PurchaseBox = (p) => {
  const sessionConfig = getReducer(sessionReducerName).sessionConfig;
  return (
    <StyledPurchaseBox className={' '}>
      <div className={'purchase-box mx-2'}>
        {!p.responsive ? (
          <div className={'ic-col'}>
            {p.discountedPrice && p.discountedPrice < p.price ? (
              <PurchaseBoxInfoSection {...p} />
            ) : (
              <div className={'text-sm font-bold text-center'}>
                {sessionConfig.isDecimalPricingEnabled ? localeSvc.formatDecPrice(p.price) : localeSvc.formatLongPrice(p.price)}
              </div>
            )}

            {p.unitsPerPackage > 1 ? (
              <div className={'ic-font-smaller text-center'}>
                {' '}
                {p.discountedPrice && p.discountedPrice < p.price ? (
                  <Info>
                    {p.unitsPerPackage} x <StrikeThrough> {localeSvc.formatDecPrice(p.price / p.unitsPerPackage)}</StrikeThrough>{' '}
                    <Discount> {localeSvc.formatDecPrice(p.discountedPrice / p.unitsPerPackage)}</Discount>
                  </Info>
                ) : (
                  <Info>
                    {p.unitsPerPackage} x {localeSvc.formatDecPrice(p.price / p.unitsPerPackage)}{' '}
                  </Info>
                )}
              </div>
            ) : null}
          </div>
        ) : null}
        <div className={'h-3/4'}>
          <QuantityCounterMod
            {...p}
            inc={() => p.incrementQuantity(p.quantity + 1, p.code)}
            count={p.quantity}
            dec={() => p.decrementQuantity(p.quantity - 1, p.code)}
            setCount={p.setCount}
            key={p.code}
            iid={p.code}
          />
        </div>
        <Button
          className={'my-2 p-3 w-full addToCartButton flex flex-row justify-center'}
          uppercase={true}
          loading={p.spinning}
          onClick={() => {
            p.addToCart(p.quantity, p.code, p.categoryCode);
          }}
          disabled={p.spinning || p.status === 'NOT_BUYABLE'}
          buttonIcon={Icons.ShoppingBasket}
          buttonText={str('basket.add.to.basket')}
        />
      </div>
      <div className={'flex justify-center'}>
        <StockStatus
          availability={p.stockStatus ? p.stockStatus.availability : null}
          stockStatusDescription={p.stockStatus ? p.stockStatus.stockStatusDescription : null}
        />
      </div>
    </StyledPurchaseBox>
  );
};

const RightBar = styled.div`
  flex: 1;
  flex-basis: ${(props) => (props.responsive ? '60%' : '40%')};
  max-width: ${(props) => (props.responsive ? '60%' : '40%')};
`;
const LeftBar = styled.div`
  flex-basis: ${(props) => (props.responsive ? '40%' : '60%')};
  max-width: ${(props) => (props.responsive ? '40%' : '60%')};
  flex: 1;
  margin-left: ${theme.space * 1}rem;
  margin-bottom: ${theme.space * 1}rem;
`;

const StyledProductBar = styled.div`
  background: ${(props) => props.col.bg};
  color: ${(props) => props.col.fg};
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h2 {
    margin: 0;
    padding: 0;
  }
`;

/* eslint-enable */
const ProductBar = (p) => (
  <StyledProductBar
    col={getColorFromCode(p.code)}
    className={'m-2 p-1 font-bold text-2xl text-center e2e-ver-listing-product-title'}
  >
    <h2>{p.name ? p.name.toUpperCase() : p.code.toUpperCase()}</h2>
  </StyledProductBar>
);

const ProductContent = styled.div`
  display: flex;
`;

const ProductContainer = styled.div`
  &&& {
    display: flex;
    @media (min-width: ${theme.size.sm}px) {
      flex-basis: 50%;
      max-width: 50%;
      padding-left: ${theme.space / 4}rem;
      padding-right: ${theme.space / 4}rem;
    }
  }
`;

const ProductInfo = styled.div`
  &&& {
    /* background: url('/_ui/dist/theme-minespresso/images/Product_background.jpg');
    background-repeat: repeat-y; */
    display: flex;
    width: 100%;
    position: relative;
    flex-direction: column;
    /* background-color: ${(p) => p.theme.col.pale.fg}; */
    background-color: rgb(243, 243, 243);
    color: ${(p) => p.theme.textColor};
    .smaller,
    .small-price {
      text-align: right;
    }

    .product-img {
      flex: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-top: ${theme['double-padding']};
    }
    table {
      width: 100%;
      padding-top: ${theme.space}rem;
    }
    circle {
      stroke: ${(p) => p.theme.textColor};
    }
    td path {
      fill: ${(p) => p.theme.textColor};
    }
    .quantityCounter {
      width: 100%;
      height: 46px;
      margin-left: 0;
      a {
        box-sizing: border-box;
        height: 38px;
        width: 33%;
        text-align: center;
        border-color: ${theme.col.primary.fg};
        background: ${theme.col.primary.fg};

        span {
          margin-top: 5px;
          width: 100%;
          color: white;
        }
      }

      input.txtQty {
        width: 34%;
        box-sizing: border-box;
        height: 38px;
        font-family: 'Roboto', Arial, sans-serif;
      }
    }
  }
`;

const ButtonContainer = styled.div`
  &&& {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    .ic-ui-button {
      color: white;
    }
    margin-bottom: ${theme.space}rem;
    margin-top: ${theme.space}rem;
  }
`;

const ProductImageContainer = styled.div`
  img {
    width: 178px;
  }
`;

const Discount = styled.div`
  color: ${(p) => p.theme.col.vars.priceDiscountActive};
`;

const StrikeThrough = styled.div`
  text-decoration: line-through;
  color: ${(p) => p.theme.col.vars.priceDiscountOverruled};
  margin-right: ${(p) => p.theme.space / 2}rem;
`;

const ProductInfoSection = (p) => {
  return (
    <>
      {p.discountedPrice && p.discountedPrice < p.price ? (
        <Info>
          <StrikeThrough className={'text-s1 font-bold text-center'}>{localeSvc.formatLongPrice(p.price)}</StrikeThrough>
          <Discount className={'text-s1 font-bold text-center'}>{localeSvc.formatLongPrice(p.discountedPrice)}</Discount>
        </Info>
      ) : (
        <div className={'text-s1 font-bold text-center'}>{localeSvc.formatLongPrice(p.price)}</div>
      )}

      {p.unitsPerPackage > 1 ? (
        <div className={'ic-font-smaller text-center'}>
          {' '}
          {p.discountedPrice && p.discountedPrice < p.price ? (
            <Info>
              {p.unitsPerPackage} x <StrikeThrough> {localeSvc.formatDecPrice(p.price / p.unitsPerPackage)}</StrikeThrough>{' '}
              <Discount>{localeSvc.formatDecPrice(p.discountedPrice / p.unitsPerPackage)}</Discount>
            </Info>
          ) : (
            <Info>
              {p.unitsPerPackage} x {localeSvc.formatDecPrice(p.price / p.unitsPerPackage)}{' '}
            </Info>
          )}
        </div>
      ) : null}
    </>
  );
};

const Product = (p) => (
  <ProductContainer className={'flex flex-col md:flex-row '}>
    <ProductInfo className={''}>
      <ProductBar color={p.color} price={p.price} name={p.name} value={p.strength} code={p.code} images={p.img} />
      <ProductContent>
        <LeftBar responsive={p.responsive}>
          <Characteristics {...p} responsive={p.responsive} />
          {!p.responsive ? (
            <div>
              <CleanText value={p.text} />
            </div>
          ) : null}
          <ButtonContainer>
            <Button
              className="py-2 px-8"
              buttonColor={ButtonColors.PANEL}
              uppercase={true}
              onClick={() => {
                navigation.navigateTo(p.history, p.url ? p.url : location.pathname.substr(6) + '/p/' + p.code);
              }}
              buttonText={str('phrase.read_more')}
            />
          </ButtonContainer>
        </LeftBar>
        <RightBar responsive={p.responsive}>
          {p.images ? (
            <ProductImageContainer>
              <ProductImg
                code={p.code}
                size={'medium'}
                images={p.images}
                galleryIndex={0}
                // eslint-disable-next-line no-nested-ternary
                url={theme.conf.showPDPLink ? (p.url ? p.url : '/c/' + p.categoryCode + '/p/' + p.code) : ''}
                alt={p.name}
                title={p.name}
                productInfoMessage={p.productInfoMessage}
              />
            </ProductImageContainer>
          ) : (
            <PodImg url={theme.conf.showPDPLink ? '/c/' + p.categoryCode + '/p/' + p.code : ''} alt={p.name} title={p.name} />
          )}
          {p.responsive ? (
            <div className={'ic-col'}>
              <ProductInfoSection {...p} />
            </div>
          ) : (
            <PurchaseBox {...p} />
          )}
        </RightBar>
      </ProductContent>
      {p.responsive ? (
        <div className={'ic-margin-lr'}>
          <PurchaseBox {...p} responsive={true} />
          <CleanText value={p.text} />
        </div>
      ) : null}
    </ProductInfo>
  </ProductContainer>
);

class Product_ extends React.Component {
  static get propTypes() {
    return {
      count: PropTypes.any,
      code: PropTypes.string,
    };
  }

  // shouldComponentUpdate(nextProps) {
  //   // if (!this.props.count) {
  //   //   return typeof nextProps.count !== 'undefined';
  //   // }
  //   // if (!this.props.count[this.props.code]) {
  //   //   return typeof nextProps.count[this.props.code] !== 'undefined';
  //   // }
  //   // return (
  //   //   nextProps.count[nextProps.code].count !== this.props.count[this.props.code].count ||
  //   //   nextProps.discountedPrice !== this.props.discountedPrice
  //   // );
  // }
  render() {
    const p = this.props;
    return (
      <>
        <ForNonDesktop>
          <Product responsive={true} {...p} />
        </ForNonDesktop>
        <ForDesktop>
          <Product responsive={false} {...p} />
        </ForDesktop>
      </>
    );
  }
}

export default Product_;
