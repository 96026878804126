import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { getTheme } from '@ic-theme';
import logger from 'score/logSvc';
import getColorfromCode from 'ic/sites/minespresso/getColorFromCode';

const name = 'MEProductTitleBar';
const log = logger.getLogger(name); // eslint-disable-line
const theme = getTheme(name, {
  duration: '0.4s',
  col: {
    primary: {
      fg: 'blue',
      alt: 'white',
    },
  },
});

const TitleBar = styled.div`
  &&& {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => getColorfromCode(props.color).bg};
    h1 {
      color: ${(props) => getColorfromCode(props.color).fg};
      margin: 0;
      padding: ${theme.padding};
      font-family: 'Open Sans', sans-serif;
    }
  }
`;

const Title = styled.h1``;
export default class MEProductTitleBar extends React.Component {
  /**
   * propTypes
   * @property {string} color
   * @property {string} name
   * * @property {string} code
   */
  static get propTypes() {
    return {
      color: PropTypes.string,
      name: PropTypes.string,
      code: PropTypes.string,
    };
  }

  render() {
    return (
      <TitleBar color={this.props.color} className={'ic-half-margin-b'}>
        <Title className={'ic-padding'}>{this.props.name ? this.props.name.toUpperCase() : this.props.code.toUpperCase()}</Title>
      </TitleBar>
    );
  }
}

MEProductTitleBar.defaultProps = {};
