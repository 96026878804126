import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Image from 'ic/ui-elem/Image';
import cloneDeep from 'fast-clone';
import { setupInitialState, mapItems, setupMutations, mapService, areStatePropsEqual } from 'core/util/module-utils';
import MediaQuery from 'react-responsive';
import { str } from 'seco/localeSvc';
import { getters as navigationSvcGetters, findSection, svcData as navData } from 'seco/navigationSvc/navigationSvcMod';
import NewsLetter from 'eco/NewsLetter/NewsLetterMod.jsx';
import styled from 'styled-components';
import { getThemeFactory } from '@ic-theme';
import { NAME as sessionReducerName } from '@spa-core/store/app/constants';
import { TrackingActionTypes } from '@spa-core/tracking/constants';

const multipleInstances = false;
const name = 'BasicFooter';
const th = getThemeFactory(name, {});

const initialState = {
  newsLetter: { email: '', errorMessage: '', successMessage: '', buttonLabel: 'word.send' },
  socialIcons: [
    {
      src: 'images/icons/facebook_brown.jpg',
      url: '#',
      id: 'facebook',
    },
    {
      src: 'images/icons/instagram_brown.jpg',
      url: '#',
      id: 'instagram',
    },
  ],
};

// ################# GETTERS  #################
const getters = (state, ownProps) => {
  const instance = cloneDeep(mapItems(state, conf, ownProps.iid));
  mapService(navigationSvcGetters(state, ownProps), instance);

  const sessionConfig = state?.reducers?.[sessionReducerName]?.sessionConfig;
  instance.sessionConfig = sessionConfig;
  instance.socialIcons[0].url = sessionConfig?.facebookUrl;
  instance.socialIcons[1].url = sessionConfig?.instagramUrl;
  return instance;
};

// ################# ACTIONS  #################

const actions = (dispatch, ownProps) => ({
  setPath: (path, nav) => {
    dispatch({
      type: 'PAGE_VIEW',
      analytics: true,
      iid: ownProps.iid,
      path,
      location: ownProps.location,
      history: ownProps.history,
      nav,
    });
    dispatch({
      type: TrackingActionTypes.PAGE_VIEW,
      payload: {
        iid: ownProps.iid,
        path,
        location: ownProps.location,
        history: ownProps.history,
        nav,
      },
    });
  },
});

// ################# MUTATIONS  #################
/* eslint-disable no-param-reassign, no-unused-vars */
const mutations = {
  PAGE_VIEW: (state, action) => {
    state.path = action.path;
  },
};

// ################# MODULE SETUP DONT TOUCH  #################
const conf = { multipleInstances, name, initialState };
export const _module = {
  name, state: setupInitialState(initialState, conf), actions, mutations: setupMutations(mutations, conf) // eslint-disable-line
};
// ################# RENDER  #################

const Links = (p) => (
  <div className="flex flex-col md:flex-row justify-center my-2">
    {p.nodeLinks.map((item) => (
      <div className={'ic-proportion-1 item'} key={item.uid}>
        <NavLink to={item.url} activeClassName="active" key={p.prefix + '#' + item.uid}>
          {item.title}
        </NavLink>
      </div>
    ))}
  </div>
);

const SocialIcons = (p) => (
  <div className="social-icons flex py-4">
    {p.socialIcons.map((item) => (
      <a className={'ic-proportion-1 item'} key={item.id} href={item.url} target="_blank">
        <Image src={`${p.themeResourcePath}/${item.src}`} />
      </a>
    ))}
  </div>
);

const StyledFooter = styled.div`
  &&& {
    .ic-me-footer {
      clear: both;
      .active {
        text-decoration: underline;
      }
      .links {
        font-family: 'Open Sans', sans-serif;
        a {
          font-weight: 600;
          color: ${(p) => p.theme.col.secondary.alt};
          text-transform: uppercase;
        }
        a:hover {
          text-decoration: underline;
        }
        .item {
          flex-basis: auto;
          flex-grow: 0;
          padding-left: 14px;
          padding-right: 14px;
        }
        .bottomNav a {
          font-size: 13pt;
          font-weight: normal;
        }
        .secondNavRow a {
          font-size: 11pt !important;
        }
        .notice {
          font-size: 9pt;
        }
        .responsive .item {
          display: flex;
          padding: 0;
          flex-basis: 100%;
          justify-content: center;
          line-height: 1.8em;
        }
        .newsletter {
          .heading {
            padding-bottom: 0;
            h4 {
              color: #ffffff;
              font-weight: 700 !important;
            }
          }
          .ic-ui-input {
            color: #000000;
          }
          .ic-ui-button button.large {
            line-height: 1;
          }
        }
      }
    }
  }
`;

class BasicFooter extends React.Component {
  componentDidMount() {}

  /**
   * Render function for react, called very time there is state change.
   * @returns {Html} The rendered code
   */
  render() {
    const topNodes = navData.footerNodes ? navData.footerNodes[0].nodes[0].nodes : [];
    const bottomNodes = navData.footerNodes ? navData.footerNodes[0].nodes[1].nodes : [];
    const activeSection = findSection(this.props.path, navData.footerNodes);
    const newsLetterHeading = str('phrase.exclamation.recieve_our_news_letter');
    const desktop = (
      <div className={'ic-me-footer w-full ic-grid-noGutter print:hidden'}>
        <div className={'ic-col-12 col-secondary my-2'}>
          <div className={'links ic-grid-center-bottom'}>
            <div className={'ic-col-9 ic-col_md-12 ic-grid-center-bottom mt-2'}>
              <SocialIcons socialIcons={this.props.socialIcons} themeResourcePath={this.props.sessionConfig?.themeResourcePath} />
              <div className={'newsletter ic-col-9 ic-col_md-8 ic-col_sm-12 flex ic-grid-center-bottom'}>
                <div className={'heading ic-col-12 ic-col_sm-12 my-4'}>
                  <h4 className="ic-unset-margin">{newsLetterHeading}</h4>
                </div>
                <div className={'ic-col-12 ic-col_sm-12 ic-grid-center-bottom'}>
                  <NewsLetter color={'primary'} {...this.props} />
                </div>
              </div>
            </div>
            <div className={'bottomNav ic-col-12 flex ic-grid-center-bottom my-2'}>
              <Links activeSection={activeSection} nodeLinks={topNodes} prefix={'deksktop1'} key="desktop1" />
            </div>
            <div className={'bottomNav secondNavRow ic-col-12 flex ic-grid-center-bottom my-2'}>
              <Links activeSection={activeSection} nodeLinks={bottomNodes} prefix={'deksktop2'} key="desktop1" />
            </div>
            <div className={'notice ic-padding-top ic-grid-center-bottom ic-col-12'}>{this.props.footerNotice}</div>
          </div>
        </div>
      </div>
    );
    const mobile = (
      <div className={'ic-me-footer w-full ic-grid-noGutter print:hidden'}>
        <div className={'ic-col-12 col-secondary ic-padding-lr ic-padding-tb'}>
          <div className={'links ic-grid-center-bottom'}>
            <div className={'ic-col-9 ic-col_md-12 ic-grid-center-bottom'}>
              <SocialIcons socialIcons={this.props.socialIcons} />
              <div className={'newsletter ic-col-9 ic-col_md-8 ic-col_sm-12 flex ic-grid-center-bottom'}>
                <div className={'heading ic-col-12 ic-col_sm-12 text-center pb-2'}>
                  <h4 className="ic-unset-margin">{newsLetterHeading}</h4>
                </div>
                <div className={''}>
                  <NewsLetter color={'primary'} {...this.props} />
                </div>
              </div>
            </div>
            <div className={'bottomNav flex flex-col text-center'}>
              {navData.footerNodes.map((section) => (
                <Links nodeLinks={topNodes} prefix={'mobile'} key={section.uid} />
              ))}
              {navData.footerNodes.map((section) => (
                <Links activeSection={activeSection} nodeLinks={bottomNodes} prefix={'mobile'} key={section.uid} />
              ))}
            </div>
            <div className={'notice ic-col-12 ic-grid-center-bottom'}>{this.props.footerNotice}</div>
          </div>
        </div>
      </div>
    );
    return (
      <>
        <StyledFooter theme={th()}>
          <MediaQuery query="(max-width: 580px)">{mobile}</MediaQuery>
          <MediaQuery query="(min-width: 581px)">{desktop}</MediaQuery>
        </StyledFooter>
      </>
    );
  }
}
BasicFooter.contextTypes = { store: PropTypes.object };
BasicFooter.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};
const ExportedBasicFooter = connect(getters, actions, undefined, {
  areStatePropsEqual,
})(BasicFooter);

require("core/redux/reducer").registerModule(name, _module);require("@spa-core/redux/store").newModuleLoaded();
export default ExportedBasicFooter;
