import React from 'react'
import { str } from '@spa-ec-js/services/localeSvc'
import Link from '@ui-elem-js/Link'
import StructuredSEOData from '@spa-ec-js/displayComponents/StructuredSEOData'
import classNames from 'classnames'
import { Breadcrumb as BreadcrumbInterface } from '@spa-core/store/breadcrumbs/interfaces'
import Icon, { Icons } from '@ui-elem/Icon/Icon'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { NAME as sessionReducerName } from '@spa-core/store/app/constants'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'

enum Styles {
    breadcrumbsColor = 'breadcrumbs_color',
    breadcrumbsTailColor = 'breadcrumbs_tail_color',
}

const createItem = (itemName, url, urlPrefix, pos) => {
    return {
        '@type': 'ListItem',
        position: pos,
        name: itemName,
        item: location.origin + urlPrefix + url,
    }
}

type ComponentState = {
    sessionConfig: SessionConfig
}

type Props = {
    breadcrumbs: BreadcrumbInterface[]
    homepageLabel?: string
}

const Breadcrumb: React.FC<Props> = ({ breadcrumbs, homepageLabel = '' }) => {
    const HOME_URL: string = '/'

    const sessionConfigStore = ({ reducers }: Store) => reducers[sessionReducerName].sessionConfig
    const selector = createSelector(
        [sessionConfigStore],
        (sessionConfig): ComponentState => ({
            sessionConfig,
        }),
    )
    const { sessionConfig }: ComponentState = useSelector(selector)

    const breadcrumbData = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [createItem(homepageLabel, HOME_URL, sessionConfig.urlPrefix, 1)],
    }

    breadcrumbs?.forEach((breadcrumb, index) => {
        breadcrumbData.itemListElement.push(createItem(breadcrumb.name, breadcrumb.url, sessionConfig.urlPrefix, index + 2))
    })

    return (
        <>
            {breadcrumbs?.length ? (
                <>
                    <StructuredSEOData data={breadcrumbData} />
                    <div className={'flex md:flex-row'}>
                        <ul className={'flex flex-wrap my-1 -space-x-10'}>
                            <div className={classNames('e2e-ver-home-icon mt-1 md:mt-0 mr-11', Styles.breadcrumbsColor)}>
                                <Link title={str('breadcrumb.home')} to={HOME_URL}>
                                    <Icon icon={Icons.Home} size={24} className={'p-1'} />
                                </Link>
                            </div>
                            {breadcrumbs.map((breadcrumb, index) => (
                                <li className={'flex flex-row z-auto mt-1 md:mt-0'} key={`${breadcrumb.name} desktop`}>
                                    {index > 0 ? (
                                        <div
                                            className={classNames(
                                                'flex h-6 w-6 rotate-45 self-center translate-x-3 rounded-tr-md rounded-bl-md z-auto',
                                                Styles.breadcrumbsTailColor,
                                            )}
                                        />
                                    ) : null}
                                    <Link to={breadcrumb.url}>
                                        <div
                                            className={classNames(
                                                'py-1 pl-5 pr-4 line-clamp-1 hover:underline',
                                                Styles.breadcrumbsColor,
                                            )}
                                        >
                                            {breadcrumb.name || breadcrumb.shortName}
                                        </div>
                                    </Link>
                                    <div
                                        className={classNames(
                                            'flex h-6 w-6 rotate-45 self-center -translate-x-3 rounded-tr-md rounded-bl-md  rounded-tl rounded-br z-10',
                                            Styles.breadcrumbsColor,
                                        )}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                </>
            ) : (
                <div className={'home breadcrumbs_color mt-1 md:mt-0'}>
                    <Link title={str('breadcrumb.home')} to={HOME_URL}>
                        <Icon icon={Icons.Home} size={24} className={'p-1'} />
                    </Link>
                </div>
            )}
        </>
    )
}

export default Breadcrumb
