import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import Panel from 'ic/ui-elem/Panel';
import Icon, { Icons } from '@ui-elem/Icon/Icon';
import logger from 'score/logSvc';
import { getThemeFactory } from '@ic-theme';
import classNames from 'classnames';
import * as cls from './Gallery.styles';

const log = logger.getLogger('Gallery 🌸 '); // eslint-disable-line

// eslint-disable-next-line no-unused-vars
const theme = getThemeFactory('Gallery', {})();

const fixImages = (thumbnail, enlargedImages) => {
  let i;
  const res = [];
  for (i = 0; i < Math.min(thumbnail.length, enlargedImages.length); i++) {
    res.push({
      original: enlargedImages[i].url,
      thumbnail: thumbnail[i].url,
    });
  }

  return res;
};

export default class Gallery extends React.Component {
  static get propTypes() {
    return {
      thumbnails: PropTypes.array,
      enlargedImages: PropTypes.array,
      onClose: PropTypes.func,
      startPosition: PropTypes.number,
      thumbnailPosition: PropTypes.string,
    };
  }

  componentDidMount() {}
  render() {
    return (
      <div id={'test-gallery-screen'} className={cls.screen}>
        <div className={cls.overlay} />
        <Panel
          padding={'half'}
          thumbnails={this.props.thumbnails}
          hideThumbnailsInZoomedLayout={this.props.hideThumbnailsInZoomedLayout}
          className={classNames(
            this.props.thumbnails && this.props.thumbnails.length === 1 ? cls['full-width-for-single-thumb'] : '',
            this.props.hideThumbnailsInZoomedLayout ? cls['hide-thumbnails-in-zoomed-layout'] : '',
            cls['gallery-panel'],
            'flex flex-row'
          )}
        >
          <ImageGallery
            thumbnailPosition={!this.props.hideThumbnailsInZoomedLayout ? this.props.thumbnailPosition : 'top'}
            items={fixImages(this.props.thumbnails, this.props.enlargedImages)}
            showFullscreenButton={false}
            showPlayButton={false}
            startIndex={this.props.startPosition}
            showThumbnails={!this.props.hideThumbnailsInZoomedLayout && this.props.thumbnails && this.props.thumbnails.length > 1}
          />
          <Icon
            icon={Icons.Times}
            size={32}
            className={'cursor-pointer'}
            onClick={() => {
              this.props.onClose(this.props.productCode);
            }}
          />
        </Panel>
      </div>
    );
  }
}

Gallery.defaultProps = {
  thumbnailPosition: 'right',
};
