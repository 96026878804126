import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cloneDeep from 'fast-clone';
import { Metadata } from 'deco/Head';
import { superConnect, setupInitialState, mapItems, setupMutations, mapGetters } from 'core/util/module-utils';
import { getters as prodSvcGetters } from 'seco/productsSvc/productsSvcMod';
import each from 'lodash/each';
import Spinner from 'ic/ui-elem/Spinner';
import ProductStateCard from './MEProductStateCardMod';
import { NAME as sessionReducerName } from '@spa-core/store/app/constants';
import { NAME as productsReducerName } from '@spa-core/store/products/constants';
import { NAME as categoriesReducerName } from '@spa-core/store/categories/constants';
import { getReducer } from '@spa-core/legacy-adapter/utils';
import { fetchProductByCode } from '@spa-core/store/products/actions';
import { fetchCategory } from '@spa-core/store/categories/actions';
import { svcData as svcNavData } from '@spa-ec-js/services/navigationSvc/navigationSvcMod';

// Setup module multi/single instance name etc
const multipleInstances = false;
const name = 'productListing';

// Modules data, this is the initial data for an instance
const initialState = {
  products: {},
  isLoading: {},
  categoryCode: '',
  showSpinner: true,
};

const isEnKronaConsumed = (obj, key) => {
  if (!obj) {
    return false;
  }
  if (obj[key]) {
    return obj[key] === true;
  }
};

const conf = { multipleInstances, name, initialState };
let reported = false;

// ################# GETTERS  #################
export const getters = (state, ownProps) => {
  // Leave this line fetches ta state variable depending on the module is using instances or not
  const instance = cloneDeep(mapItems(state, conf, ownProps.iid));
  // Adding an extra getter that is not only a variable but some calculation based on the state
  mapGetters(prodSvcGetters(state, ownProps), instance, 'productsSvc', ['getCategory', 'getProduct', 'categoryData']);
  instance.products = state?.reducers?.[productsReducerName]?.products;
  instance.slimProducts = state?.reducers?.[productsReducerName]?.slimProducts;
  instance.categories = state?.reducers?.[categoriesReducerName]?.categories;
  return instance;
};

// ################# ACTIONS  #################
export const actions = (dispatch, ownProps) => {
  const self = {
    reportItemList(catData, productCodes, products, slimProducts) {
      // Reporting to ga4
      const items = [];
      each(productCodes, ({ code }) => {
        const _product = products?.[code] || slimProducts?.[code];
        items.push({
          item_id: _product.code,
          item_name: _product.name,
          currency: _product.currency,
          price: _product.price,
          b2bStore: getReducer(sessionReducerName).sessionConfig?.b2bMode,
          discount: _product.discountedPrice ? _product.price - _product.discountedPrice : 0,
        });
      });
      dispatch({
        type: 'view_item_list',
        item_list_id: catData.categoryCode,
        item_list_name: catData.categoryName,
        analytics: true,
        items,
      });
    },
    setCategory: (code) =>
      dispatch({
        type: 'ME_PL_SET_CURRENT_CATEGORY',
        iid: ownProps.iid,
        code,
      }),
    setSpinner: () =>
      dispatch({
        type: 'ME_PL_SET_SPINNER',
      }),
    clearSpinner: () =>
      dispatch({
        type: 'ME_PL_CLEAR_SPINNER',
      }),
    getProduct: (productCode) => {
      const products = getReducer(productsReducerName)?.products;
      const product = products?.[productCode];
      if (!product) {
        dispatch(fetchProductByCode({ productCode }));
      }
      return product;
    },
    fetchCategory: (categoryCode, callback) => {
      dispatch(
        fetchCategory({
          categoryCode,
          callback,
          headerNodes: svcNavData.headerNodes || {},
        })
      );
    },
  };
  return self;
};

// ################# MUTATIONS  #################
/* eslint-disable no-param-reassign, no-unused-vars */
const mutations = {
  ME_PL_SET_SPINNER: (state) => {
    state.showSpinner = true;
  },
  ME_PL_CLEAR_SPINNER: (state) => {
    state.showSpinner = false;
  },
  ME_PL_SET_CURRENT_CATEGORY: (state, action) => {
    state.categoryCode = action.code;
  },
};

/* eslint-enable */

// ################# MODULE SETUP DON T TOUCH  #################
export const _module = {
  name,
  state: setupInitialState(initialState, conf),
  actions,
  getters,
  mutations: setupMutations(mutations, conf), // eslint-disable-line
};

// ################# RENDER  #################
class MEProductListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, reported: false };
  }

  componentDidMount() {
    const categoryCode = this.props.match.params.categoryCode;
    this.props.setSpinner();
    this.props.fetchCategory(categoryCode, this.props.clearSpinner);
  }
  componentWillUnmount() {
    reported = false;
  }

  setReported() {
    reported = true;
  }

  /**
   * Render function for react, called very time there is state change.
   * @returns {Html} The rendered code
   */

  render() {
    const p = this.props;
    const categoryCode = p.match.params.categoryCode;
    const catData = p.categories?.[categoryCode];
    const productCodes = p.categories?.[categoryCode]?.productCodes || [];

    const productsLoaded = productCodes.every(({ code }) => p.products?.[code] || p.slimProducts?.[code]);

    if (!productsLoaded || !catData || p.showSpinner) {
      return (
        <div className={'ic-double-padding'}>
          <Spinner visible={true} />
        </div>
      );
    }
    if (!reported) {
      setTimeout(() => {
        // Defer while we wait for gtm to be setup
        p.reportItemList(catData, productCodes, p.products, p.slimProducts);
      }, 0);
    }

    return (
      <>
        <Metadata metadata={catData.metadata} appendSiteName={true} />

        <div className="ic-grid-centered-noGutter">
          {productCodes.map(({ code }) => {
            const product = p.products?.[code] || p.slimProducts?.[code];
            if (!product) return;
            return (
              <Fragment key={product.code}>
                <ProductStateCard
                  {...product}
                  toggle={p.toggle}
                  themeUrl={p.themeUrl}
                  key={product.code}
                  iid={product.code}
                  history={p.history}
                  categoryCode={categoryCode}
                  stockStatus={product.stockStatus}
                  isTrailProduct={product.isTrialProduct && !isEnKronaConsumed(p.session.enkronaUsed, product.oneBuyUserGroupId)}
                  disabledPurchaseButton={p.sessionConfig?.oneBuyUserGroupMap?.[product.oneBuyUserGroupId] || false}
                />
              </Fragment>
            );
          })}
        </div>
      </>
    );
  }
}
MEProductListing.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

require("core/redux/reducer").registerModule(name, _module);require("@spa-core/redux/store").newModuleLoaded();
export default withRouter(superConnect(_module, MEProductListing));
