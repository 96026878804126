import React from 'react';
import { getThemeFactory } from '@ic-theme';
import { Icons } from '@ui-elem/Icon/Icon';
import classNames from 'classnames';
import Button, { ButtonColors, IconAlignments } from '@ui-elem/Button/Button';

// Setup module multi/single instance name etc
// const multipleInstances = true;
const name = 'QuantityCounter';
const th = getThemeFactory(name, {
  roundIcon: false,
  modeIcon: '',
  iconSize: '1x',
  iconButtonSize: '2x',
  iconType: 'light',
  hoverType: 'solid',
  iconColor: 'tertiary',
  iconButtonColor: 'tertiary',
  iconButtonflexibleWidth: '', // A Minespresso special
  noBorderOnCountField: false,
  readOnly: true,
});
const theme = th();

export const MAX_QUANTITY = 100;
export const MIN_QUANTITY = 1;

class QuantityCounter extends React.Component {
  render() {
    const p = this.props;

    return (
      <div className={classNames('flex flex-row gap-1 justify-center')}>
        <Button
          className={classNames('p-2 quantityCounterButton')}
          buttonColor={ButtonColors.SECONDARY}
          buttonIcon={Icons.Minus}
          iconAlignment={IconAlignments.CENTER}
          onClick={p.dec}
        />
        <input
          className={'w-1/5 text-center quantityCounterInput'}
          readOnly={typeof p.readOnly === 'undefined' ? theme.readOnly : p.readOnly}
          value={p.count}
          onChange={(e) => {
            e.preventDefault();
            const newQuantity = parseInt(e.target.value, 10);
            if (typeof newQuantity === 'number' && newQuantity < MAX_QUANTITY) {
              p.setCount(newQuantity);
            } else {
              p.setCount(0);
            }
          }}
          onBlur={(e) => {
            e.preventDefault();
            const newQuantity = parseInt(e.target.value, 10);
            if (typeof newQuantity === 'number' && newQuantity === 0) {
              p.setCount(1);
            }
          }}
        />

        <Button
          className={classNames('p-2 quantityCounterButton')}
          buttonColor={ButtonColors.SECONDARY}
          buttonIcon={Icons.Plus}
          iconAlignment={IconAlignments.CENTER}
          onClick={p.inc}
        />
      </div>
    );
  }
}

export default QuantityCounter;
