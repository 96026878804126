import PropTypes from 'prop-types';
import React from 'react';
import logger from 'score/logSvc';
import MEProductListing from 'ic/sites/minespresso/components/MEProductListing/MEProductListingMod.jsx';
import PageTitle from 'eco/StaticPageComponents/PageTitleMod.jsx';
import { Metadata } from 'deco/Head';
import ContentSlot from 'eco/ContentSlot/ContentSlotMod.jsx';

const log = logger.getLogger('MEProductListingPage'); // eslint-disable-line

export default class MEProductListingPage extends React.Component {
  static get propTypes() {
    return {
      metadata: PropTypes.object,
      title: PropTypes.string,
      isLoggedIn: PropTypes.bool,
    };
  }

  /**
   * Function called before mounting component. Do initialisations here that should not happen every render, potentially
   * serveral times per second.
   */
  componentDidMount() {}

  /**
   * Render function for react, called very time there is state change.
   * @returns {Html} The rendered code
   */
  render() {
    const categoryCode = this.props.match.params.categoryCode;
    return (
      <>
        <Metadata metadata={this.props.metadata} appendSiteName={false} />
        {this.props.title ? <PageTitle isloggedIn={this.props.isLoggedIn} title={this.props.title} /> : null}
        <ContentSlot
          iid={this.props.pageId + '---TopBannerArea_' + categoryCode}
          key={this.props.pageId + '---TopBannerArea_' + categoryCode}
          SSRKey={this.props.pageId + '---TopBannerArea_' + categoryCode}
          position={'TopBannerArea'}
          pageId={this.props.pageId}
          category={categoryCode}
        />
        <MEProductListing {...this.props} />
        <ContentSlot
          iid={this.props.pageId + '---BottomBannerArea_' + categoryCode}
          key={this.props.pageId + '---BottomBannerArea_' + categoryCode}
          SSRKey={this.props.pageId + '---BottomBannerArea_' + categoryCode}
          position={'BottomBannerArea'}
          pageId={this.props.pageId}
          category={categoryCode}
        />
      </>
    );
  }
}
MEProductListingPage.contextTypes = {
  store: PropTypes.object,
};
