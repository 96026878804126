import { getStore } from '@spa-core-js/redux/store';
import { getReducer } from '@spa-core/legacy-adapter/utils';
import { ActionTypes as ProductActionTypes } from '@spa-core/store/products/constants';
import { parseProduct } from '@spa-core/store/products/utils';
import { NAME as sessionReducerName } from '@spa-core/store/app/constants';
import { getState as getFullState } from '@spa-core/redux/store';
import memo from 'core/util/memo';
import { getModuleState, mapItems, setupInitialState, setupMutations } from 'core/util/module-utils';
import { actions as messageActions } from 'eco/GlobalMessages/GlobalMessagesMod';
import cloneDeep from 'fast-clone';
import each from 'lodash/each';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import React from 'react';
import { connect } from 'react-redux';
import logger from 'score/logSvc';
import net from 'score/networkSvc';
import { getEmptyProduct, getProduct, transformToBasicProduct } from './product-factories';

// Setup module multi/single instance name etc
const multipleInstances = false;
const name = 'ProductsSvc';

export const productShelfColumns = [
  {
    columnId: 'productImage',
    size: 1,
    classes: '',
    title: '',
    defaultTitle: '',
  },
  {
    columnId: 'productCode',
    size: 1,
    classes: '',
    title: 'product.code.short',
    defaultTitle: 'Produktnr',
  },
  {
    columnId: 'productCorrespondsTo',
    size: 1,
    classes: '',
    title: 'product.code.replaces',
    defaultTitle: '',
  },
  {
    columnId: 'productManufacturer',
    size: 1,
    classes: '',
    title: 'product.manufacturer',
    defaultTitle: '',
  },
  {
    columnId: 'productName',
    size: 1,
    classes: '',
    title: 'word.product',
    defaultTitle: '',
  },
  {
    columnId: 'stock',
    size: 1,
    classes: '',
    title: 'product.variants.in.stock',
    defaultTitle: '',
  },
  {
    columnId: 'price',
    size: 1,
    classes: '',
    title: 'product.tablerow.price.title',
    defaultTitle: '',
  },
  {
    columnId: 'addToCartAndSubscription',
    size: 1,
    classes: 'text-right',
    title: '',
    defaultTitle: '',
  },
  {
    columnId: 'productUsage',
    size: 1,
    classes: '',
    title: 'product.tablerow.yield.title',
    defaultTitle: 'Innehåll',
  },
  {
    columnId: 'pageCapacity',
    size: 1,
    classes: '',
    title: 'product.tablerow.spec.text',
    defaultTitle: 'Kapacitet',
  },
  {
    columnId: 'environmental',
    size: 1,
    classes: '',
    title: 'product.tablerow.environmental.title',
    defaultTitle: 'Miljö',
  },
];

/* eslint-disable no-unused-vars */
export const productDataValitityTest = {
  productImage: (_product) => true,
  productCode: (_product) => true,
  productCorrespondsTo: (product, prev) => (prev ? true : !!product.correspondsTo),
  productName: (_product) => true,
  productUsage: (product, prev) => (prev ? true : !!product.inkVolume),
  pageCapacity: (product, prev) => (prev ? true : !!product.pageYield),
  productManufacturer: (_product) => true,
  environmental: (product, prev) => (prev ? true : product.environmental),
  stock: (_product) => true,
  price: (product, prev) => (prev ? true : typeof product.price !== 'undefined'),
  addToCartAndSubscription: (_product) => true,
};
/* eslint-enable */

// Mudules data, this is the initial data for an instance
const initialState = {
  products: [],
  productCodes: [],
  productDb: {},
  productDbSlimDTO: {},
  loadStatusDb: {},
  facets: [],
  sorts: [],
  selectedFacetQuery: '',
  selectedSort: '',
  categoryCode: '',
  iid: 'productsSvc',
  showCategoryProductSpinner: true,
  fetchingSingleProductActive: false,
  metadata: {},
  lastUpdate: new Date('1970-01-01').getTime(),
  lastCall: {},
  productRelations: [],
  productShelfForModelData: {},
  replacementProduct: {},
  categoryData: {},
  sections: {},
  sectionColumns: {},
  shelfData: {},
  upsellingProducts: {},
  oneBuyProducts: {},
  freeCategoriesProducts: {},
  orderUpsellProducts: {},
};

export const loadStatus = {
  PRE_FETCH: 'PRE_FETCH',
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
  NOT_FOUND: 'NOT_FOUND',
};

const log = logger.getLogger(name); // eslint-disable-line
const conf = { multipleInstances, name, initialState };

const _getProductByCode = function get(productCode) {
  const dbProduct = this.productDb[productCode];
  if (!dbProduct) {
    const store = getStore().getState();
    const prod = store?.reducers?.products?.products?.[productCode];
    return prod;
  }
  if (dbProduct && !dbProduct.slimDTO) {
    return dbProduct;
  }

  // Initial state for a product (in PDP for starters)
  return getEmptyProduct();
};
const _getBasicProductByCode = function get(basicProductCode) {
  const dbProductSlimDTO = this.productDbSlimDTO[basicProductCode];
  // first check in the productDbSlimDTO, if not found then check in productDb
  if (!dbProductSlimDTO) {
    const dbProduct = this.productDb[basicProductCode];
    if (dbProduct) {
      return transformToBasicProduct(dbProduct);
    }
  } else if (dbProductSlimDTO) {
    return dbProductSlimDTO;
  }
  return getEmptyProduct();
};
const _getCategory = function get(categoryCode, selectedSort, facetQuery, page) {
  const key = categoryCode + '_' + selectedSort + '_' + facetQuery + '_' + page;
  return this.categoryData[key] ? this.categoryData[key] : [];
};
const _getFacets = function get(categoryCode, selectedSort, facetQuery, page) {
  const key = categoryCode + '_' + selectedSort + '_' + facetQuery + '_' + page;
  const res = this.categoryData[key] ? this.categoryData[key].facets : [];
  if (typeof res === 'undefined') return [];
  return res;
};
const _getSorting = function get(categoryCode, selectedSort, facetQuery, page) {
  const key = categoryCode + '_' + selectedSort + '_' + facetQuery + '_' + page;
  const res = this.categoryData[key] ? this.categoryData[key].sorts : [];
  if (typeof res === 'undefined') return [];
  return res;
};

/**
 * '
 */
const getProductByCode = memo('lastUpdate', 'getCode', [0], _getProductByCode);
const getBasicProductByCode = memo('lastUpdate', 'getCode', [0, 1], _getBasicProductByCode);
const getCategory = memo('lastUpdate', 'getCategory', [0, 1, 2, 3], _getCategory);
const getFacets = memo('lastUpdate', 'getFacets', [0, 1, 2, 3], _getFacets);
const getSorting = memo('lastUpdate', 'getSorting', [0, 1, 2, 3], _getSorting);

// ################# GETTERS  #################
export const getters = (state, ownProps) => {
  // Leave this line fetches ta state variable depending on the module is using instances or not
  const instance = cloneDeep(mapItems(state, conf, ownProps.iid));

  instance.getProduct = getProductByCode.bind(instance);
  instance.getBasicProduct = getBasicProductByCode.bind(instance);
  instance.getCategory = getCategory.bind(instance);
  instance.getFacets = getFacets.bind(instance);
  instance.getSorting = getSorting.bind(instance);
  return instance;
};

// const lastCall = { time: new Date('1970-01-01') };
// ################# ACTIONS  #################
export const actions = (dispatch, ownProps) => ({
  invalidateProductDb: () => {
    // Do nothing until PDP crach is fixed
    // dispatch({ type: 'PROD_INVALIDATE_PRODUCTDB', noAnalytics: true });
  },
  fetchSingleProd: (productCode, supressProductNotFoundError) =>
    dispatch(async (_disp, getState) => {
      const globalMessages = messageActions(_disp, ownProps);
      const state = getModuleState(getState(), ownProps.iid, conf);

      // IC-13760 Disabling check as the time comparison is not working
      // root cause for this is that product data from content resets the last update date  which stamping the prodcut data as valid
      // event though the product data for the product in the PDP is not valid
      // if (checkIfProductDBIsValid(state, productCode)) {
      //   return;
      // }
      if (!state.fetchingSingleProductActive && productCode) {
        dispatch({ type: 'PROD_SINGLE_FETCHING', noAnalytics: true, value: true });
        dispatch({ type: 'UPDATE_PRODUCT_LOAD_STATUS', noAnalytics: true, status: loadStatus.FETCHING, code: productCode });
        try {
          const sessionConfig = getReducer(sessionReducerName).sessionConfig;
          const result = await net.get(sessionConfig.urlPrefix + `/rest/v2/products/${productCode}`, { cache: 'none' });
          dispatch({ type: 'PROD_SINGLE', noAnalytics: true, data: result, productCode });
          dispatch({ type: 'UPDATE_PRODUCT_LOAD_STATUS', noAnalytics: true, status: loadStatus.FETCHED, code: productCode });
        } catch (err) {
          if (supressProductNotFoundError && err.status === 400) {
            log.info('Error in fetching single prod data ', err);
          } else {
            globalMessages.addRestCallFailed(`/rest/v2/products/${productCode}`, 'GET', err);
          }
          dispatch({ type: 'PROD_SINGLE_FETCHING', noAnalytics: true, value: false });
          dispatch({ type: 'UPDATE_PRODUCT_LOAD_STATUS', noAnalytics: true, status: loadStatus.NOT_FOUND, code: productCode });
        }

        dispatch({ type: 'PROD_SINGLE_FETCHING', noAnalytics: true, value: false });
      }
    }),

  /**
   * Fetching that will replace fetchProd
   */
  fetchCategory: (categoryCode, sortCode, facetQuery, page, cb, previewLimit) => {
    return new Promise((resolve, reject) => {
      const now = new Date().getTime();

      const state = getModuleState(getFullState(), ownProps.iid, conf); // eslint-disable-line

      if (
        state.lastCall.categoryCode === categoryCode &&
        state.lastCall.sortCode === sortCode &&
        state.lastCall.facetQuery === facetQuery &&
        state.lastCall.page === page &&
        now - state.lastUpdate < 15000 &&
        !logger.cacheDisabled()
      ) {
        cb();
        return;
      }

      const lastCall = {
        categoryCode,
        sortCode,
        facetQuery,
        page,
        time: now,
      };

      dispatch(async (_disp) => {
        const globalMessages = messageActions(_disp, ownProps);

        let data = '';
        if (sortCode) {
          data = `sortCode=${sortCode}&`;
        }
        if (facetQuery) {
          data += `q=${facetQuery}&`;
        }
        if (page) {
          data += `page=${page}`;
        }
        if (previewLimit) {
          data += `previewLimit=${previewLimit}`;
        }

        try {
          const sessionConfig = getReducer(sessionReducerName).sessionConfig;
          const result = await net.get(sessionConfig.urlPrefix + '/rest/v2/categories/' + categoryCode + '/products?' + data, {
            cache: 'none',
          });

          if (result) {
            const keyParams = { categoryCode, sortCode, facetQuery: facetQuery || '', page: page || 0 };
            _disp({
              type: 'PROD_SVC_CATEGORY_DATA',
              noAnalytics: true,
              keyParams,
              data: result,
              category: categoryCode,
              selectedFacetQuery: facetQuery || '',
              selectedSort: sortCode,
              currentPage: 0,
              lastCall,
              previewLimit,
              metadata: {
                title: result.title,
                metaTitle: result.metaTitle,
                metaDescription: result.metaDescription,
                description: result.description,
                keywords: result.keywords,
              },
            });
            if (cb) cb();
            resolve(keyParams);
          } else if (cb) cb();
        } catch (err) {
          globalMessages.addRestCallFailed('/rest/v2/categories/' + categoryCode + '/products?' + data, 'GET', err);

          reject(err);
        }
      });
    });
  },
  fetchProductRelations: (productCode, cb) => {
    dispatch(async (_disp) => {
      try {
        const sessionConfig = getReducer(sessionReducerName).sessionConfig;
        const result = await net.get(sessionConfig.urlPrefix + '/rest/v1/products/modelcategories/' + productCode);
        _disp({ type: 'PROD_SHOW_PRODUCT_RELATIONS', noAnalytics: true, data: result });
        if (cb) cb(result);
      } catch (err) {
        log.error('error: ', err);
      }
    });
  },
  fetchProductShelfForModel: (productCode, modelCode) => {
    dispatch(async (_disp) => {
      try {
        const sessionConfig = getReducer(sessionReducerName).sessionConfig;
        const result = await net.get(
          `${sessionConfig.urlPrefix}/p/${productCode}/getProductShelfForModelSvc?modelCode=${modelCode}`
        );
        _disp({ type: 'PROD_SHOW_PRODUCT_SHELF_PAGE', noAnalytics: true, data: { modelCode, data: result } });
      } catch (err) {
        log.error('error', err);
      }
    });
  },
  fetchReplacementInfo: (code, quantity) => {
    dispatch(async (_disp) => {
      try {
        const sessionConfig = getReducer(sessionReducerName).sessionConfig;
        const result = await net.get(
          `${sessionConfig.urlPrefix}/rest/v1/products/replacementproduct/${code}?quantity=${quantity}`
        );
        _disp({ type: 'PROD_REPLACEMENT_INFO', noAnalytics: true, data: { productCode: code, data: result } });
      } catch (err) {
        log.error('error', err);
      }
    });
  },
  fetchProductShelfSections: (modelCode, currentPageProductCode, cb) => {
    dispatch(async (_disp) => {
      const sessionConfig = getReducer(sessionReducerName).sessionConfig;
      const globalMessages = messageActions(_disp);
      try {
        const result = await net.get(`${sessionConfig.urlPrefix}/rest/v2/productShelf/${modelCode}?source=fetchBrands`);
        const recommended = result.recommended?.map(({ products }) => products.map(parseProduct))?.flat() || [];
        const budget = result.budget?.map(({ products }) => products.map(parseProduct))?.flat() || [];
        const original = result.original?.map(({ products }) => products.map(parseProduct))?.flat() || [];
        const optimum = result.optimum?.map(({ products }) => products.map(parseProduct))?.flat() || [];
        const upselling = result.upselling?.map(({ products }) => products.map(parseProduct))?.flat() || [];
        const freeCategoriesList = result.freeCategoriesList?.map(({ products }) => products.map(parseProduct))?.flat() || [];
        const modelProductsDB = [...recommended, ...budget, ...original, ...optimum, ...upselling, ...freeCategoriesList];
        _disp({
          type: ProductActionTypes.FETCHED_PRODUCTS,
          payload: {
            products: modelProductsDB,
          },
        });
        _disp({
          type: 'PROD_SET_SHELF_SECTIONS',
          iid: ownProps.iid,
          modelCode,
          modelProductsDB,
          currentPageProductCode,
          data: result,
        });
      } catch (err) {
        globalMessages.addRestCallFailed(`${sessionConfig.urlPrefix}/rest/v2/productShelf/${modelCode}`, 'GET', err);
      }
      if (cb) {
        cb();
      }
    });
  },
  fetchOrderUpsellProducts: (orderCode) => {
    dispatch(async (_disp) => {
      const globalMessages = messageActions(_disp);
      const sessionConfig = getReducer(sessionReducerName).sessionConfig;
      try {
        const result = await net.get(`${sessionConfig.urlPrefix}/rest/v2/order/upsell/${orderCode}`);
        _disp({ type: 'PROD_SET_ORDER_UPSELL_PRODUCTS', iid: ownProps.iid, orderCode, data: result });
      } catch (err) {
        globalMessages.addRestCallFailed(`${sessionConfig.urlPrefix}/rest/v2/order/upsell/${orderCode}`, 'GET', err);
      }
    });
  },
  fetchProductsInBatch: (productCodes, slimAPI) => {
    dispatch(async (_disp, getState) => {
      if (!productCodes.length || productCodes.length === 0) {
        return;
      }
      const state = getModuleState(getState(), conf.multipleInstances ? ownProps.iid : undefined, conf);
      const pCodes = slimAPI
        ? productCodes.filter((code) => !state.productDbSlimDTO[code])
        : productCodes.filter((code) => !state.productDb[code]);
      const globalMessages = messageActions(_disp);
      while (pCodes.length > 0) {
        // Making call for the product codes in chunks of 50, as the backend API
        // supports returns maximum 50 products in one call for security purpose
        const temp = pCodes.splice(0, 50);
        const pCodesParam = temp.join(',');
        const sessionConfig = getReducer(sessionReducerName).sessionConfig;
        net
          .get(`${sessionConfig.urlPrefix}/rest/v2/batch/products?codes=${pCodesParam}${slimAPI ? '&slim=true' : ''}`)
          .then((r) => {
            const rProducts = Object.values(r)
              .filter((v) => v)
              .map((v) => getProduct(v));
            _disp({ type: 'PROD_PRODUCTS', noAnalytics: true, products: rProducts });
          })
          .catch((err) =>
            globalMessages.addRestCallFailed(
              `${sessionConfig.urlPrefix}/rest/v2/batch/products?codes=${pCodesParam}${slimAPI ? '&slim=true' : ''}`,
              'GET',
              err
            )
          );
      }
    });
  },
});

// ################# MUTATIONS  #################
/* eslint-disable no-param-reassign, no-unused-vars */
const mutations = {
  PRODUCT_HIDE_SPINNER: (state, action) => {
    state.showCategoryProductSpinner = false;
  },
  PROD_LASTCALL: (state, action) => {
    state.lastUpdate = action.lastCall.time;
    state.lastCall = action.lastCall;
  },
  PROD_SVC_CATEGORY_DATA: (state, action) => {
    try {
      const key =
        action.keyParams.categoryCode +
        '_' +
        action.keyParams.sortCode +
        '_' +
        action.keyParams.facetQuery +
        '_' +
        action.keyParams.page;
      const categoryData = {};

      if (
        state.selectedFacetQuery !== action.selectedFacetQuery ||
        state.selectedSort !== action.selectedSort ||
        state.categoryCode !== action.category
      ) {
        state.selectedFacetQuery = action.selectedFacetQuery ? action.selectedFacetQuery : '';
        state.selectedSort = action.selectedFacetQuery ? action.selectedSort : '';
        state.categoryCode = action.category;
      }

      categoryData.selectedFacetQuery = action.selectedFacetQuery;
      categoryData.selectedSort = action.selectedSort;
      categoryData.categoryCode = action.category;
      const actionData = action.data;
      categoryData.parentCategoryName = actionData.parentCategoryName;
      categoryData.alternativeCategoryHeader = actionData.alternativeCategoryHeader;
      categoryData.categoryName = actionData.categoryName;
      categoryData.description = actionData.description;
      categoryData.infoText = actionData.infoText;
      categoryData.preamble = actionData.preamble;

      const newProducts = [];
      const newProductCodes = [];

      each(actionData.results, (productData) => {
        const product = getProduct(productData);
        newProductCodes.push(product.code);
        state.productDb[productData.code] = product;
      });

      categoryData.products = newProducts;
      categoryData.productCodes = newProductCodes;
      const newFacets = actionData.facets;

      resolveBreadCrumbs(actionData, newFacets);

      categoryData.facets = newFacets;

      categoryData.sorts = actionData.sorts;
      categoryData.currentQuery = actionData.currentQuery;
      categoryData.brand = actionData.brand;
      categoryData.categoryImageURL = actionData.categoryImageURL;
      categoryData.virtualShelfPageId = actionData.virtualShelfPageId;
      categoryData.productType = actionData.productType;

      // Meta-data
      categoryData.metadata = action.metadata;
      if (!isEqual(categoryData, state.categoryData[key])) {
        state.lastUpdate = new Date().getTime();
        state.categoryData[key] = categoryData;
      }
    } catch (e) {
      log.error('Got error', e);
    }
  },
  PROD_PRODUCTS: (state, action) => {
    each(action.products, (prod) => {
      // state.productDb[prod.code] = prod;
      if (!prod.slimDTO) {
        state.productDb[prod.code] = prod;
      }
      if (prod.slimDTO) {
        state.productDbSlimDTO[prod.code] = prod;
      }
    });
    state.lastUpdate = new Date().getTime();
  },
  PROD_SINGLE: (state, action) => {
    const newProdEntry = getProduct(action.data);
    if (!isEqual(state.productDb[action.productCode], newProdEntry)) {
      state.productDb[action.productCode] = newProdEntry;
      state.lastUpdate = new Date().getTime();
    }
  },
  PROD_SINGLE_FETCHING: (state, action) => {
    state.fetchingSingleProductActive = action.value;
  },
  PROD_SHOW_PRODUCT_RELATIONS: (state, action) => {
    state.productRelations = action.data;
  },
  PROD_SHOW_PRODUCT_SHELF_PAGE: (state, action) => {
    state.productShelfForModelData[action.data.modelCode] = action.data.data;
  },
  PROD_REPLACEMENT_INFO: (state, action) => {
    state.replacementProduct[action.data.productCode] = { data: action.data.data, lastUpdate: new Date().getTime() };
  },
  PROD_SET_SHELF_SECTIONS: (state, action) => {
    if (typeof state.sections[action.modelCode] === 'undefined') {
      state.sections[action.modelCode] = {
        budget: [],
        recommended: [],
        original: [],
        optimum: [],
        upselling: [],
        freeCategories: [],
      };
    }

    if (typeof state.sectionColumns[action.modelCode] === 'undefined') {
      state.sectionColumns[action.modelCode] = {
        budget: {},
        recommended: {},
        original: {},
        optimum: {},
        oneBuyProducts: {},
        upselling: {},
        freeCategories: {},
      };
    }

    // Getting products for each section for model
    let modelHasProducts = false;
    const currentPageProductCode = action.currentPageProductCode ? action.currentPageProductCode : '';
    const budget = action.data.budget;
    const recommended = action.data.recommended;
    const original = action.data.original;
    const optimum = action.data.optimum;
    const upselling = action.data.upselling;
    const freeCategories = action.data.freeCategoriesList;

    // Getting list of 1kr offers for model
    const oneBuyProductsList =
      state.oneBuyProducts[action.modelCode] && state.oneBuyProducts[action.modelCode].length > 0
        ? state.oneBuyProducts[action.modelCode]
        : [];

    // Creating sections and default section product data
    const sections = { recommended, budget, original, optimum, upselling, freeCategories };
    let productsHasInkVolume = false;
    let productsHasPageYield = false;

    // Populate each section and subsection with data
    each(sections, (section, key) => {
      if (section.length > 0) {
        modelHasProducts = true;
        const sectionData = [];

        each(section, (subSection) => {
          let productList = [];

          resolveSubSection(
            subSection,
            currentPageProductCode,
            state,
            productList,
            oneBuyProductsList,
            productsHasInkVolume,
            productsHasPageYield,
            action,
            key
          );

          // Create products data and push it to section data
          const productsData = {
            code: subSection.code,
            name: subSection.name,
            description: subSection.description,
            products: productList,
          };
          sectionData.push(productsData);
        });

        if (typeof state.sections[action.modelCode][key] === 'undefined') {
          state.sections[action.modelCode][key] = [];
        }

        // Add section data to sections
        state.sections[action.modelCode][key] = sectionData;
      }
    });

    state.sections[action.modelCode].oneBuyProducts = oneBuyProductsList;

    each(oneBuyProductsList, (product) => {
      if (typeof state.sectionColumns[action.modelCode].oneBuyProducts !== 'undefined') {
        let productDetails = state.productDb[product];
        if (!productDetails && Array.isArray(action.modelProductsDB)) {
          const filteredProducts = action.modelProductsDB?.filter((p) => product === p.code);
          if (filteredProducts?.length > 0) {
            productDetails = filteredProducts[0];
          }
        }
        if (productDetails) {
          each(productShelfColumns, (column) => {
            state.sectionColumns[action.modelCode].oneBuyProducts[column.columnId] = productDataValitityTest[column.columnId](
              productDetails,
              state.sectionColumns[action.modelCode].oneBuyProducts[column.columnId]
            );
          });
        }
      }
    });

    state.sections[action.modelCode].modelHasProducts = modelHasProducts;
    state.shelfData[action.modelCode] = {};
    state.shelfData[action.modelCode].categoryImage = action.data.categoryImageUrl;
    state.shelfData[action.modelCode].brandImageUrl = action.data.brandImageUrl;
    state.shelfData[action.modelCode].title = action.data.title;
    state.shelfData[action.modelCode].metaTitle = action.data.metaTitle;
    state.shelfData[action.modelCode].description = action.data.description;
    state.shelfData[action.modelCode].metaDescription = action.data.metaDescription;
    state.shelfData[action.modelCode].heading = action.data.heading;
    state.shelfData[action.modelCode].keywords = action.data.keywords;
    state.shelfData[action.modelCode].brand = action.data.brand;
    state.shelfData[action.modelCode].shelfPageTypeId = action.data.shelfPageTypeId;
    state.shelfData[action.modelCode].productsHasInkVolume = productsHasInkVolume;
    state.shelfData[action.modelCode].productsHasPageYield = productsHasPageYield;
  },
  PROD_CLEAR_SHELF_SECTIONS: (state, action) => {
    state.sections = initialState.sections;
    state.lastUpdate = initialState.lastUpdate;
    state.prevModelCode = '';
  },
  PROD_SET_ORDER_UPSELL_PRODUCTS: (state, action) => {
    if (action.data.length > 0) {
      each(action.data, (product) => {
        state.productDb[product.code] = getProduct(product);
        if (!state.orderUpsellProducts) {
          state.orderUpsellProducts = {};
        }
        if (!state.orderUpsellProducts[action.orderCode]) {
          state.orderUpsellProducts[action.orderCode] = [];
        }
        if (!state.orderUpsellProducts[action.orderCode].includes(product.code)) {
          state.orderUpsellProducts[action.orderCode].push(product.code);
        }
      });
      state.lastUpdate = new Date().getTime();
    }
  },
  UPDATE_PRODUCT_LOAD_STATUS: (state, action) => {
    state.loadStatusDb[action.code] = action.status;
  },
  PROD_INVALIDATE_PRODUCTDB: (state, action) => {
    state.lastUpdate = new Date(2000, 0, 1);
  },
};

const resolveBreadCrumbs = (actionData, newFacets) => {
  if (actionData.breadcrumbs.length > 0) {
    // update state facets list by adding selected facets in it
    each(actionData.breadcrumbs, (breadcrumb) => {
      let existInFacets = false;
      const selectedFacet = {};
      const facetValue = {};
      selectedFacet.code = breadcrumb.facetCode;
      selectedFacet.name = breadcrumb.facetName;
      selectedFacet.values = [];
      facetValue.code = breadcrumb.facetValueCode;
      facetValue.name = breadcrumb.facetValueName;
      facetValue.query = breadcrumb.removeQuery;
      facetValue.selected = true;
      selectedFacet.values.push(facetValue);

      resolveFacet();

      if (!existInFacets) {
        newFacets.push(selectedFacet);
      }
    });
  }
};

const resolveFacet = (newFacets, breadcrumb, existInFacets, facetValue) => {
  if (newFacets && newFacets.length > 0) {
    each(newFacets, (facet) => {
      if (facet.code === breadcrumb.facetCode) {
        existInFacets = true;
        // if facetValue is not in values
        let existInFacetValues = false;
        each(facet.values, (value) => {
          if (value.code === breadcrumb.facetValueCode) {
            existInFacetValues = true;
          }
        });
        if (!existInFacetValues) {
          facet.values.push(facetValue);
        }
        facet.values = sortBy(facet.values, (o) => o.name);
      }
    });
  }
};

const resolveSubSection = (
  subSection,
  currentPageProductCode,
  state,
  productList,
  oneBuyProductsList,
  productsHasInkVolume,
  productsHasPageYield,
  action,
  key
) => {
  if (subSection.products && subSection.products.length > 0) {
    each(subSection.products, (product) => {
      if (product.price !== null) {
        resolveProduct(
          currentPageProductCode,
          product,
          state,
          productList,
          oneBuyProductsList,
          productsHasInkVolume,
          productsHasPageYield
        );

        resolveColumn(state, action, key, product);
      } else {
        console.error('Can not resolve price for product  ' + product.code);
      }
    });
  }
};

const resolveProduct = (
  currentPageProductCode,
  product,
  state,
  productList,
  oneBuyProductsList,
  productsHasInkVolume,
  productsHasPageYield
) => {
  if (!(currentPageProductCode === product.code)) {
    state.productDb[product.code] = getProduct(product);
  }
  state.lastUpdate = new Date().getTime();
  productList.push(product.code);

  if (product.oneBuyOnlyProduct && oneBuyProductsList.indexOf(product.code) === -1) {
    oneBuyProductsList.push(product.code);
  }
  if (product.inkVolume) {
    productsHasInkVolume = true;
  }
  if (product.pageYield) {
    productsHasPageYield = true;
  }
};

const resolveColumn = (state, action, key, product) => {
  if (typeof state.sectionColumns[action.modelCode][key] !== 'undefined') {
    each(productShelfColumns, (column) => {
      state.sectionColumns[action.modelCode][key][column.columnId] = productDataValitityTest[column.columnId](
        product,
        state.sectionColumns[action.modelCode][key][column.columnId]
      );
    });
  }
};

/* eslint-enable */
// ################# MODULE SETUP DON T TOUCH  #################
export const _module = {
  name,
  state: setupInitialState(initialState, conf),
  actions,
  mutations: setupMutations(mutations, conf), // eslint-disable-line
};

// ################# RENDER  #################
class ProductSvcMod extends React.Component {
  /**
   * Render function for react, called very time there is state change.
   * @returns {Html} The rendered code
   */
  render() {
    return <div iid="productSvc" />;
  }
}

require("core/redux/reducer").registerModule(name, _module);require("@spa-core/redux/store").newModuleLoaded();
export default connect(getters, actions)(ProductSvcMod);
