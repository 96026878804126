import loadable from '@loadable/component';
import ContentPage from 'reco/ContentPage/ContentPage.jsx';
import MEProductListingPage from 'ic/sites/minespresso/routes/MEProductListingPage/MEProductListingPage.jsx';
import ProductDetailPage from 'ic/sites/minespresso/routes/MEProductDetailPage/MEProductDetailPage.jsx';
import { ErrorTypes, SpaError } from '@spa-ec/components/Error/Error';

const ContactUsPage = loadable(() => import('reco/ContactUsPage/ContactUsPage.jsx'));
const AccountPage = loadable(() => import('reco/AccountPage/AccountPage.jsx'));
const QliroCheckoutPage = loadable(() => import('@spa-ec/routes/QliroCheckoutPage/QliroCheckoutPage'));
const CheckoutPage = loadable(() => import('reco/CheckoutPage/CheckoutPage.jsx'));
const OrderConfirmationPage = loadable(() => import('reco/OrderConfirmationPage/OrderConfirmationPage.jsx'));
const CartPage = loadable(() => import('@spa-ec/routes/CartPage/CartPage'));
const LoginPage = loadable(() => import('reco/LoginPage/LoginPage.jsx'));
const PasswordRequestPage = loadable(() => import('reco/PasswordRequestPage/PasswordRequestPage.jsx'));
const PasswordChangePage = loadable(() => import('reco/PasswordChangePage/PasswordChangePage.jsx'));
const CustomerFeedbackPage = loadable(() => import('@spa-ec/routes/CustomerFeedbackPage/CustomerFeedbackPage')); // eslint-disable-line

const getComponentByType = (type) => {
  switch (type) {
    case 'STATIC_PAGE':
    case 'DEFAULT':
    case 'CONTENT':
      return ContentPage;
    case 'PRODUCT_LISTING':
      return MEProductListingPage;
    case 'PRODUCT_DETAIL':
      return ProductDetailPage;
    case 'CART':
      return CartPage;
    case 'CHECKOUT':
      return CheckoutPage;
    case 'QLIRO_CHECKOUT':
      return QliroCheckoutPage;
    case 'ORDER_CONFIRMATION':
      return OrderConfirmationPage;
    case 'account':
    case 'ACCOUNT':
      return AccountPage;
    case 'LOGIN':
      return LoginPage;
    case 'PASSWORD_CHANGE':
      return PasswordChangePage;
    case 'PASSWORD_REQUEST':
      return PasswordRequestPage;
    case 'CONTACT_US':
      return ContactUsPage;
    case 'CUSTOMER_FEEDBACK':
      return CustomerFeedbackPage;
    default:
      return () => {
        throw new SpaError({ errorType: ErrorTypes.NOT_FOUND });
      };
  }
};

export default getComponentByType;
