import React, { useEffect } from 'react';
import { getThemeFactory } from '@ic-theme';
import debounce from 'lodash/debounce';
import * as cls from './Cheapest.styles';
import classnames from 'classnames';
import { NAME as sessionReducerName } from '@spa-core/store/app/constants';
import { getReducer } from '@spa-core/legacy-adapter/utils';

const name = 'Cheapest';
const th = getThemeFactory(name, {
  bottom: '0px',
  left: '94px',
  prisjakt: {},
  version: 'v1',
  formats: {
    sm1: 'small1',
    sm2: 'small2',
    sm3: 'small3',
    sm4: 'small4',
    md1: 'medium1',
    md2: 'medium2',
  },
});

const debouncedReload = debounce(() => {
  window.prisjakt_cheapest.reload();
}, 100);

const Cheapest = (p) => {
  useEffect(() => {
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line sonarjs/no-collapsible-if
      if (window.PRISJAKT_CHEAPEST) {
        if (window.prisjakt_cheapest) {
          // debouncedReload();
          setTimeout(() => {
            // window.prisjakt_cheapest.reload();
            debouncedReload();
          }, 500);
        }
      }
    }
  }, []);

  const theme = th();

  if (typeof window !== 'undefined') {
    if (!window.PRISJAKT_CHEAPEST) return null;
  } else {
    // Dont render this on the serverside
    return null;
  }
  if (!theme) return null;
  if (!theme.prisjakt) return null;

  const sessionConfig = getReducer(sessionReducerName).sessionConfig;
  if (!theme.prisjakt[sessionConfig.currentBaseStoreId]) return null;

  let topDomain = 'nu';
  let market = 'SE';
  if (sessionConfig.currentBaseStoreId.indexOf('-no') > -1) {
    topDomain = 'no';
    market = 'NO';
  }

  const storeId = theme.prisjakt[sessionConfig.currentBaseStoreId];
  const linkUrl = `https://instore.prisjakt.${topDomain}/cheapest_redirect.php?id=${p.code}&f=${storeId}`;
  const imageUrl = `https://instore.prisjakt.${topDomain}/cheapest.php?id=${p.code}&f=${storeId}`;
  return (
    <div
      className={classnames(
        cls.prisjaktlabelwrapper,
        p.rightPosition ? cls['prisjaktlabelwrapperright-' + p.size] : cls['prisjaktlabelwrapperleft-' + p.size]
      )}
      theme={theme}
    >
      {theme.version === 'v1' ? (
        <a href={linkUrl} target="_blank">
          <img src={imageUrl} />
        </a>
      ) : (
        <div
          className={classnames('prisjakt_cheapest')}
          shop={storeId}
          product={p.code}
          market={market}
          format={theme.formats[p.size]}
        />
      )}
    </div>
  );
};

export default Cheapest;
