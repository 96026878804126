const themeGen = theme => ({ // eslint-disable-line
  fixedIconButtonHeight: '38px',
  fixedIconButtonWidth: '33%',
  fixedCounterWidth: '34%',
  fixedCounterHeight: '36px',
  iconColor: 'primary',
  iconButtonColor: 'primary',
  iconButtonflexibleWidth: 'w-1/3 py-2',
  iconButtonSize: '',
  iconSize: 'lg',
});

export default themeGen;
